import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import PlatformSelector from './PlatformSelector';
import { tablet, useQuery } from 'styles/breakpoints';
import Carousel from './Carousel';

export type PlatformType = 'ios' | 'android';

interface HowToSectionProps {
  title: string;
  ios: {
    tabTitle: string;
    steps: { text: string; image: string }[];
    storeSubtitle: string;
  };
  android: {
    tabTitle: string;
    steps: { text: string; image: string }[];
    storeSubtitle: string;
  };
  subtitle: string;
}

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark100};
  display: flex;
  justify-content: center;
  padding: 0rem 1rem 0rem 1rem;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 71.25rem;
  width: 100%;
`;

const Heading = styled(Text)`
  margin-top: 6rem;
  margin-bottom: 2rem;
  @media ${tablet} {
    margin-top: 3rem;
    text-align: center;
  }
`;

const MockupsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4.5rem;
  margin-bottom: 4rem;
  width: 100%;
`;

const MockupBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.orange80};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const MockupText = styled(Text)``;

const PlatformSpecificText = styled(Text)`
  margin-bottom: 1.5rem;
  @media ${tablet} {
    text-align: center;
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.dark40};
  height: 1px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const BelowText = styled(Text)`
  margin-bottom: 6rem;
  @media ${tablet} {
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const HowToSection: FC<HowToSectionProps> = ({
  android,
  ios,
  subtitle,
  title,
}) => {
  const [platform, setPlatform] = useState<PlatformType>('ios');
  const [slide, setSlide] = useState<number>(0);

  const steps = platform === 'android' ? android?.steps : ios?.steps;
  const text =
    platform === 'android' ? android?.storeSubtitle : ios?.storeSubtitle;
  const Button =
    platform === 'android' ? <GooglePlayButton /> : <AppleStoreButton />;

  const { isTablet } = useQuery();

  useEffect(() => {
    setSlide(0);
  }, [isTablet, platform]);

  const renderMockups = () =>
    steps?.map((mockup, index) => (
      <MockupBlock key={index}>
        <DynamicImage
          src={mockup.image}
          alt={mockup.text}
          height="20rem"
          marginBottom="1.5rem"
        />
        <TextContainer>
          <Circle>
            <Text type="captionS" color="light100">
              {index + 1}
            </Text>
          </Circle>
          <MockupText type="body" color="light100">
            {mockup?.text}
          </MockupText>
        </TextContainer>
      </MockupBlock>
    ));

  return (
    <Container>
      <ContentContainer>
        <Heading type={isTablet ? 'h3' : 'count'} color="light100">
          {title}
        </Heading>
        <PlatformSelector
          iosTabTitle={ios?.tabTitle}
          androidTabTitle={android?.tabTitle}
          platform={platform}
          onSelect={setPlatform}
        />
        {isTablet ? (
          <Carousel
            slide={slide}
            onSlide={setSlide}
            slideComponents={renderMockups()}
          />
        ) : (
          <MockupsContainer>{renderMockups()}</MockupsContainer>
        )}
        <PlatformSpecificText type="body" color="light100">
          {text}
        </PlatformSpecificText>
        {Button}
        <Divider />
        <BelowText type="body" color="light100">
          {subtitle}
        </BelowText>
      </ContentContainer>
    </Container>
  );
};

export default HowToSection;
