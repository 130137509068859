import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import { tablet, useQuery } from 'styles/breakpoints';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  max-width: 71.25rem;
  padding: 6rem 1rem 6rem 1rem;
  width: 100%;
  @media ${tablet} {
    padding: 3rem 1rem 3rem 1rem;
    flex-direction: column;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media ${tablet} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: visible;
  width: 50%;
  @media ${tablet} {
    width: 100%;
    overflow: hidden;
  }
`;

const TYTitle = styled(Text)`
  margin-bottom: 1.5rem;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const TYText = styled(Text)`
  margin-bottom: 3rem;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  height: 28.125rem;
  @media ${tablet} {
    height: 23.4375rem;
  }
`;

const TYButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 3rem;
    justify-content: center;
  }
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)`
  margin-right: 1rem;
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <TYTextContainer>
        <TYTitle type={isTablet ? 'h1' : 'h13Rem'}>{title}</TYTitle>
        <TYText type={isTablet ? 'body' : 'h3400'}>{subtitle}</TYText>
        <TYButtonsContainer>
          <AppleStoreButtonStyled />
          <GooglePlayButton />
        </TYButtonsContainer>
      </TYTextContainer>
      <ImageContainer>
        <StyledDynamicImage src={img} alt={alt} />
      </ImageContainer>
    </Container>
  );
};

export default ThankYouSection;
